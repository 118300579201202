import { useLoyaltyAccountStore } from '../../../../account/domain/stores/useLoyaltyAccountStore';
import { useNewBookingStore } from '../../../../parking/domain/booking/stores/useNewBookingsStore';

export const useRemoveOfferPromoCode = () => {
	const newBookingStore = useNewBookingStore();
	const loyaltyStore = useLoyaltyAccountStore();

	// cleanup parking session
	// remove offer promo code and selected offer from the store
	const clearPromocode = () => {
		newBookingStore.removeOfferPromoCode();
		loyaltyStore.removeOfferPromoCode();
	};

	return { clearPromocode };
};
