import {
	EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_ENTRY_DATE,
	EVENT_CATEGORY_PARKING_AVAILABILITY,
	trackEvent,
} from '../../../../../../shared/analytics';

export function trackSelectedEntryDate(entryDate: string) {
	trackEvent({
		category: EVENT_CATEGORY_PARKING_AVAILABILITY,
		action: EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_ENTRY_DATE,
		label: entryDate,
	});
}
