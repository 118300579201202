import React from 'react';
import styled from '@emotion/styled';
import { BadgeTag } from './BadgeTag';
import CancelIcon from '../../../../../assets/cancel-white.svg?reference';

interface ILoyaltyTagProps {
	text: string;
	onClick: () => void;
}

export const CancelBadgeTag = ({ text, onClick }: ILoyaltyTagProps) => (
	<StyledBadgeTag text={text}>
		<img src={CancelIcon} alt="" role="presentation" onClick={onClick} />
	</StyledBadgeTag>
);

export const StyledBadgeTag = styled(BadgeTag)``;
