import React, { FC } from 'react';
import {
	AsyncDataPlaceholder,
	Grid,
	GridItem,
	Gutter,
	H2,
	H3,
	ITheme,
	StyledInput,
	StyledTextFieldErrorMessage,
	TextField,
} from '../../../../ui';
import styled from '@emotion/styled';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import {
	getOverlapWithHeaderStyles,
	SolidContentWrap,
	StyledHeader,
	SubmitButton,
} from '../../../shared/components';
import { MaybeTimeOption } from '../../../shared/components/time-select/TimeOption';
import { EntryTimeSelect } from './time/EntryTimeSelect';
import { ExitTimeSelect } from './time/ExitTimeSelect';
import { DatePicker } from '../../../shared/components/date-picker/DatePicker';
import { MaybeDate } from '../../../shared/components/date-picker/MaybeDate';
import { StyledTimeSelect } from '../../../shared/components/time-select/TimeSelect';
import { CancelBadgeTag } from '../../../account/ui/shared/CancelBadgeTag';
import { removePromoCodeQuery } from '../../../shared/router/removePromoCodeQuery';
import { useRemoveOfferPromoCode } from '../../../shared/account/domain/hooks/useRemoveOfferPromoCode';
import { usePromoCodeFromQuery } from './helpers/usePromoCodeFromQuery';
import { PassengersPageId } from '../../../app/pages/domain/constants/PageId';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';
import { Fieldset } from '../../../shared/components/fieldset/Fieldset';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';
import { Divider } from '../../../styleguide/components/Divider';
import { theme } from '../../../shared/styles';

interface IParkingSearchMainProps {
	title?: string;
	minimumEntryDate: Date;
	entryTime: MaybeTimeOption;
	exitTime: MaybeTimeOption;
	entryDate: MaybeDate;
	exitDate: MaybeDate;
	promoCode: string;
	loyaltyPromoCode: string;
	onChangeEntryDate: (value: MaybeDate) => void;
	onChangeExitDate: (value: MaybeDate) => void;
	onChangeEntryTime: (value: MaybeTimeOption) => void;
	onChangeExitTime: (value: MaybeTimeOption) => void;
	onChangePromoCode: (value: string) => void;
	onSubmit: () => void;
	customSubmitButtonText?: string;
	isSubmitted: boolean;
	isLoading: boolean;
}

export const ParkingSearchMain: FC<IParkingSearchMainProps> = ({
	title,
	isLoading,
	entryDate,
	exitDate,
	onChangeEntryDate,
	onChangeExitDate,
	isSubmitted,
	minimumEntryDate,
	onChangeEntryTime,
	entryTime,
	onChangeExitTime,
	exitTime,
	promoCode,
	loyaltyPromoCode,
	onChangePromoCode,
	onSubmit,
	customSubmitButtonText,
}) => {
	const { t } = useTranslation();
	const { clearPromocode } = useRemoveOfferPromoCode();
	const { queryOfferId } = usePromoCodeFromQuery();
	const parkingRedirectUrl = useUrlForPageId(PassengersPageId.Parking);

	const handleCancelLoyaltyPromoCode = () => {
		clearPromocode();
		onChangePromoCode('');
		removePromoCodeQuery(parkingRedirectUrl);
	};

	return (
		<StyledParkingSearch>
			<Fieldset>
				{title && <StyledLegend>{title}</StyledLegend>}
				<StyledDivider />
				{isLoading && <AsyncDataPlaceholder />}

				<StyledGrid>
					<StyledGridItem grow columns={[3, { tabletXL: 12 }]}>
						<label htmlFor="parking-booking-date-select">
							{t('web_parking_availability_date_range_header')}
						</label>

						<StyledDateTimeInputRow>
							<DatePicker
								id="parking-booking-date-select"
								selectsRange
								selected={[entryDate, exitDate]}
								onChange={([newEntryDate, newExitDate]) => {
									onChangeEntryDate(newEntryDate);
									onChangeExitDate(newExitDate);
								}}
								invalid={isSubmitted && (!entryDate || !exitDate)}
								ariaLabel={t(
									'web_parking_availability_date_range_input_aria_label',
								)}
								placeholderText={t(
									'web_parking_availability_date_range_input_aria_label',
								)}
								disableDatesBefore={minimumEntryDate}
							/>
						</StyledDateTimeInputRow>
					</StyledGridItem>

					<StyledGridItem grow columns={[4, { tabletXL: 12 }]}>
						<label htmlFor="entry-exit-time" id="entry-exit-time">
							{t('web_parking_availability_time_selection_header')}
						</label>

						<StyledDateTimeInputRow>
							<TimeSelectWrapper>
								<EntryTimeSelect
									onChange={onChangeEntryTime}
									selectedItem={entryTime}
									entryDate={entryDate}
									minimumEntryDate={minimumEntryDate}
									ariaLabelledBy="entry-exit-time"
									ariaDescribedBy={`${t(
										'web_parking_availability_time_selection_header',
									)}-error-entry-time`}
									placeholder={t(
										'web_parking_availability_start_time_aria_label',
									)}
									invalid={isSubmitted && !entryTime}
								/>
								{isSubmitted && !entryTime && (
									<StyledTextFieldErrorMessage
										role="alert"
										id={`${t(
											'web_parking_availability_time_selection_header',
										)}-error-entry-time`}
									>
										{t('parking_booking_error_invalid_entry_time')}
									</StyledTextFieldErrorMessage>
								)}
							</TimeSelectWrapper>
							<TimeSelectWrapper>
								<ExitTimeSelect
									entryDate={entryDate}
									entryTime={entryTime}
									exitDate={exitDate}
									onChange={onChangeExitTime}
									selectedItem={exitTime}
									ariaLabelledBy="entry-exit-time"
									ariaDescribedBy={`${t(
										'web_parking_availability_time_selection_header',
									)}-error-exit-time`}
									placeholder={t(
										'web_parking_availability_end_time_aria_label',
									)}
									invalid={isSubmitted && !exitTime}
								/>
								{isSubmitted && !exitTime && (
									<StyledTextFieldErrorMessage
										role="alert"
										id={`${t(
											'web_parking_availability_time_selection_header',
										)}-error-exit-time`}
									>
										{t('parking_booking_error_invalid_exit_time')}
									</StyledTextFieldErrorMessage>
								)}
							</TimeSelectWrapper>
						</StyledDateTimeInputRow>
					</StyledGridItem>

					<StyledGridItem grow columns={[4, { tabletXL: 12 }]}>
						<label htmlFor="parking-booking-promo-code">
							{t('web_parking_availability_promo_code_header')}
						</label>

						{loyaltyPromoCode ? (
							<LoyaltyPromoCodeContainer>
								<CancelBadgeTag
									text={loyaltyPromoCode}
									onClick={handleCancelLoyaltyPromoCode}
								/>
							</LoyaltyPromoCodeContainer>
						) : (
							<TextField
								id="parking-booking-promo-code"
								name="promoCode"
								placeholder={t(
									'web_parking_availability_promo_code_placeholder',
								)}
								defaultValue={queryOfferId ? '' : promoCode}
								// @ts-ignore
								onChange={(e) => onChangePromoCode(e.currentTarget.value)}
							/>
						)}
					</StyledGridItem>
					<GridItem grow columns={[12, { tabletXL: 12 }]}>
						<StyledButtonContainer>
							<StyledSubmitButton
								type="button"
								onClick={onSubmit}
								disabled={isLoading}
							>
								{customSubmitButtonText ||
									t('web_parking_availability_submit_button')}
							</StyledSubmitButton>
						</StyledButtonContainer>
					</GridItem>
				</StyledGrid>
			</Fieldset>
		</StyledParkingSearch>
	);
};

const TimeSelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledDivider = styled(Divider)`
	margin: 24px 0;
`;

const StyledGrid = styled(Grid)`
	align-items: baseline;
`;

const StyledGridItem = styled(GridItem)`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const StyledSubmitButton = styled(SubmitButton)`
	width: auto;
	padding: 14px 16px;
	border-radius: 4px;
	box-shadow: 0px -3px 0px 0px #005b99 inset;
	outline: none;

	&:focus-visible {
		outline: ${theme.button?.primary?.focus?.outline};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		width: 100%;
	}
`;

const StyledLegend = styled.legend`
	padding: 0;
	font-size: 32px;
	font-weight: 600;
`;

const StyledDateTimeInputRow = styled.div`
	display: flex;
	gap: 16px;

	${StyledTimeSelect} {
		width: 100%;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		flex-wrap: wrap;
	}
`;

const StyledParkingSearch = styled(SolidContentWrap)<{}, ITheme>`
	/* allow inline style blocks in html output rendered by emotion */
	padding: 24px;

	${StyledHeader} + style + &,
    ${StyledHeader} + & {
		${getOverlapWithHeaderStyles()}
	}

	${H2} {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid ${themed(({ color }) => color.border.default)};
		max-width: 100%;
	}

	${Grid} {
		align-items: flex-end;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${GridItem} {
			${Gutter.cssVariableName.vertical}: 24px;
		}

		${H2} {
			font-size: 22px;
			padding-bottom: 0;
			margin-bottom: 16px;
			border-bottom: none;
		}

		${H3} {
			margin-bottom: 8px;
			font-size: 14px;
		}
	}
`;

const StyledButtonContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		padding: 0;
	}
`;

const LoyaltyPromoCodeContainer = styled(StyledInput.withComponent('div'))``;
