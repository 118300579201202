import { runInAction } from 'mobx';
import { useEffect } from 'react';
import { useStores } from '../../../../app/root/domain/stores/useStores';
import { useLocale } from '../../../../shared/i18n/locale/useLocale';

export function useNewBookingStore() {
	const { newBookingStore } = useStores();
	const { code: locale } = useLocale();

	useEffect(() => {
		runInAction(() => {
			newBookingStore.booking.locale = locale;
		});
	}, [locale]);

	return newBookingStore;
}
