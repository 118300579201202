import { trackWithGoogleAnalytics } from '../shared/trackers/trackWithGoogleAnalytics';
import { adaptItemToRequest } from './adapters/adaptItemToRequest';
import { defaultCurrencyCode } from './constants/defaultCurrency';
import { IItem } from './models/IItem';

interface ICheckoutProgressEvent {
	step: number;
	items?: IItem[];
	currency?: string;
	coupon?: string;
}

export function trackCheckoutProgress({
	step,
	items = [],
	currency,
	coupon,
}: ICheckoutProgressEvent) {
	trackWithGoogleAnalytics('event', 'checkout_progress', {
		checkout_step: step,
		items: items.map(adaptItemToRequest),
		currency: currency ?? defaultCurrencyCode,
		coupon,
	});
}
