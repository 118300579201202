import { useEffect } from 'react';
import { usePrefetch } from './usePrefetch';

export function usePrefetchEffect(url: string) {
	const prefetch = usePrefetch();

	useEffect(() => {
		prefetch(url);
	}, [url]);
}
