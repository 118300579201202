/* eslint-disable no-restricted-imports */
import { useRouter } from 'next/router';

export type IPrefetch = (url: string) => void;

export function usePrefetch(): IPrefetch {
	const router = useRouter();

	return (url: string) => router.prefetch(url);
}
