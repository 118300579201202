import React, { FC } from 'react';
import { timeOptions } from './options';
import { MaybeTimeOption } from '../../../../shared/components/time-select/TimeOption';
import {
	ITimeSelectProps,
	TimeSelect,
} from '../../../../shared/components/time-select/TimeSelect';
import { MaybeDate } from '../../../../shared/components/date-picker/MaybeDate';
import { isLocalSameDay } from '../../../../shared/i18n/date-time/LocalDateTime';

export interface IExitTimeSelectProps extends Omit<ITimeSelectProps, 'items'> {
	entryDate?: MaybeDate;
	entryTime?: MaybeTimeOption;
	exitDate?: MaybeDate;
	ariaDescribedBy?: string;
}

export const ExitTimeSelect: FC<IExitTimeSelectProps> = ({
	entryDate,
	entryTime,
	exitDate,
	...rest
}) => {
	const filteredItems = getExitTimeOptions(entryDate, entryTime, exitDate);

	return <TimeSelect items={filteredItems} {...rest} />;
};

const getExitTimeOptions = (
	entryDate?: MaybeDate,
	entryTime?: MaybeTimeOption,
	exitDate?: MaybeDate,
) => {
	if (!entryDate || !exitDate || !entryTime) return timeOptions;

	if (!isLocalSameDay(entryDate, exitDate)) return timeOptions;

	return timeOptions.filter(
		(timeOption) => timeOption.timeInMinutes > entryTime.timeInMinutes,
	);
};
