import React, { FC } from 'react';
import log from 'loglevel';
import { timeOptions } from './options';
import {
	ITimeSelectProps,
	TimeSelect,
} from '../../../../shared/components/time-select/TimeSelect';
import { MaybeDate } from '../../../../shared/components/date-picker/MaybeDate';
import {
	getLocalTimeInMinutes,
	isLocalSameDay,
} from '../../../../shared/i18n/date-time/LocalDateTime';

export interface IEntryTimeSelectProp extends Omit<ITimeSelectProps, 'items'> {
	entryDate?: MaybeDate;
	minimumEntryDate: Date;
	ariaDescribedBy?: string;
}

export const EntryTimeSelect: FC<IEntryTimeSelectProp> = ({
	entryDate,
	minimumEntryDate,
	...rest
}) => {
	const filteredItems = getEntryTimeOptions(minimumEntryDate, entryDate);

	return <TimeSelect items={filteredItems} {...rest} />;
};

const getEntryTimeOptions = (minimumDate: Date, entryDate?: MaybeDate) => {
	if (!entryDate) return timeOptions;

	if (entryDate > minimumDate) return timeOptions;

	// NB: this should actually never happen when the date selection validation is working properly
	if (!isLocalSameDay(entryDate, minimumDate)) {
		log.warn('Invalid entry date was provided');
		return [];
	}

	const minimumTotalMinutes = getLocalTimeInMinutes(minimumDate);

	return timeOptions.filter(
		(timeOption) => timeOption.timeInMinutes >= minimumTotalMinutes,
	);
};
