import {
	EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_EXIT_DATE,
	EVENT_CATEGORY_PARKING_AVAILABILITY,
	trackEvent,
} from '../../../../../../shared/analytics';

export function trackSelectedExitDate(exitDate: string) {
	trackEvent({
		category: EVENT_CATEGORY_PARKING_AVAILABILITY,
		action: EVENT_ACTION_PARKING_AVAILABILITY_SELECTED_EXIT_DATE,
		label: exitDate,
	});
}
