import { adaptPriceToTrackingValue } from './adaptPriceToTrackingValue';
import { IItem } from '../models/IItem';

export function adaptItemToRequest(item: IItem) {
	return {
		...item,
		brand: item.brand ?? 'CLT',
		quantity: item.quantity ?? 1,
		price: adaptPriceToTrackingValue(item.price),
		coupon: item.coupon,
	};
}
