import React, { useEffect, useState } from 'react';
import { add } from 'date-fns';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useNewBookingStore } from '../../domain/booking/stores/useNewBookingsStore';
import { NewBooking } from '../../domain/booking/models/NewBooking';
import { timeOptions } from './time/options';
import {
	ITimeOption,
	MaybeTimeOption,
} from '../../../shared/components/time-select/TimeOption';
import { trackSelectedEntryDate } from '../booking-steps/shared/analytics/custom/trackSelectedEntryDate';
import { trackSelectedExitDate } from '../booking-steps/shared/analytics/custom/trackSelectedExitDate';
import { useNavigate } from '../../../shared/router/useNavigate';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';
import { ParkingPageId } from '../../domain/pages/ParkingPageId';
import { usePrefetchEffect } from '../../../shared/router/usePrefetchEffect';
import { trackViewSearch } from '../booking-steps/shared/analytics/e-commerce/trackViewSearch';
import { MaybeDate } from '../../../shared/components/date-picker/MaybeDate';
import { getMinimumEntryDate } from './helpers/getMinimumEntryDate';
import { ParkingSearchMain } from './ParkingSearchMain';
import {
	getLocalStartOfDay,
	getLocalTimeInMinutes,
} from '../../../shared/i18n/date-time/LocalDateTime';

import { usePromoCodeFromQuery } from './helpers/usePromoCodeFromQuery';

export interface IParkingSearchProps {
	title?: string;
	startTimeMinimumOffsetInSeconds: number;
	// TODO: refactor to optional results URL, only navigate when provided
	shouldStayOnPage?: boolean;
	customSubmitButtonText?: string;
	shouldResetOriginalBookingState?: boolean;
}

export const ParkingSearch = observer(
	({
		shouldStayOnPage,
		shouldResetOriginalBookingState,
		startTimeMinimumOffsetInSeconds,
		...rest
	}: IParkingSearchProps) => {
		const newBookingStore = useNewBookingStore();
		const { booking, loyaltyPromoCodeTitle } = newBookingStore;

		const {
			entryTime,
			setEntryTime,
			exitTime,
			setExitTime,
			entryDate,
			setEntryDate,
			exitDate,
			setExitDate,
			promoCode,
			setPromoCode,
			isSubmitted,
			setIsSubmitted,
			isLoading,
			setIsLoading,
		} = useParkingSearchState(booking);

		const navigate = useNavigate();
		const resultsUrl = useUrlForPageId(ParkingPageId.NewBookingAvailability);

		usePrefetchEffect(resultsUrl);

		useEffect(() => {
			if (shouldResetOriginalBookingState && newBookingStore.originalBooking) {
				newBookingStore.reset();
			}
		}, [shouldResetOriginalBookingState]);

		useEffect(() => {
			trackViewSearch();
		}, []);

		const onSubmit = () => {
			setIsSubmitted(true);

			const entryDateTime = createDateTime(entryDate, entryTime);
			const exitDateTime = createDateTime(exitDate, exitTime);

			if (!entryDateTime || !exitDateTime) return;

			trackSelectedEntryDate(entryDateTime.toISOString());
			trackSelectedExitDate(exitDateTime.toISOString());

			runInAction(() => {
				booking.entryDate = entryDateTime;
				booking.exitDate = exitDateTime;
				booking.promoCode = promoCode;
			});

			if (shouldStayOnPage !== true) {
				setIsLoading(true);
				navigate(resultsUrl);
			}
		};

		const minimumEntryDate = getMinimumEntryDate(
			startTimeMinimumOffsetInSeconds,
		);

		return (
			<ParkingSearchMain
				entryDate={entryDate}
				exitDate={exitDate}
				entryTime={entryTime}
				exitTime={exitTime}
				promoCode={promoCode}
				loyaltyPromoCode={loyaltyPromoCodeTitle}
				onChangeEntryDate={setEntryDate}
				onChangeExitDate={setExitDate}
				onChangeEntryTime={setEntryTime}
				onChangeExitTime={setExitTime}
				onChangePromoCode={setPromoCode}
				onSubmit={onSubmit}
				isSubmitted={isSubmitted}
				isLoading={isLoading}
				minimumEntryDate={minimumEntryDate}
				{...rest}
			/>
		);
	},
);

export const useParkingSearchState = (booking: NewBooking) => {
	const [daysInTotal, setDaysInTotal] = useState<number | undefined>();
	const [entryDate, setEntryDate] = useState<MaybeDate>(null);
	const [exitDate, setExitDate] = useState<MaybeDate>(null);
	const [entryTime, setEntryTime] = useState<MaybeTimeOption>(null);
	const [exitTime, setExitTime] = useState<MaybeTimeOption>(null);
	const [promoCode, setPromoCode] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { queryPromoCode } = usePromoCodeFromQuery();

	useEffect(() => {
		setDaysInTotal(booking.daysInTotal);
		setEntryDate(getSelectedDate(booking.entryDate));
		setExitDate(getSelectedDate(booking.exitDate));
		setEntryTime(getSelectedTimeOption(booking.entryDate));
		setExitTime(getSelectedTimeOption(booking.exitDate));
		setPromoCode(booking.promoCode ?? '');
	}, [booking]);

	useEffect(() => {
		setPromoCode(queryPromoCode);
	}, [queryPromoCode]);

	return {
		daysInTotal,
		setDaysInTotal,
		entryDate,
		setEntryDate,
		exitDate,
		setExitDate,
		entryTime,
		setEntryTime,
		exitTime,
		setExitTime,
		promoCode,
		setPromoCode,
		isSubmitted,
		setIsSubmitted,
		isLoading,
		setIsLoading,
	};
};

const getSelectedDate = (date?: Date): MaybeDate =>
	date ? getLocalStartOfDay(date) : null;

const getSelectedTimeOption = (date?: Date): MaybeTimeOption =>
	date ? getTimeOptionForDate(date) : null;

const getTimeOptionForDate = (date: Date): ITimeOption => {
	const localTimeInMinutes = getLocalTimeInMinutes(date);

	return timeOptions.reduce((previous, current) => {
		if (current.timeInMinutes > localTimeInMinutes) return previous;

		return current.timeInMinutes > previous.timeInMinutes ? current : previous;
	});
};

const createDateTime = (date: MaybeDate, time: MaybeTimeOption) => {
	if (!date || !time) return null;

	return add(date, {
		minutes: time.timeInMinutes,
	});
};
